.global-loader-container
    height: 100vh
    width: 100vw
    background-color: $gray2

.global-loader-container.light
    background-color: $darkwhite

    
.loader-main-container
    position: absolute
    top: 0
    right: 0
    height: 100%
    width: 99%
    padding-right: 2%
    z-index: 10
    display: flex
    justify-content: center
    align-items: center
 

.loader 
  width: 50px
  aspect-ratio: 1
  border-radius: 50%
  border: 7px solid 
  border-color: $marketgreen #0000
  animation: l1 1s infinite

.loader.global
    border-color: $marketgreen #333333

.light .loader.global
    border-color: $marketgreen #f4f4f4

@keyframes l1 
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.inline-loader
    height: 1.2rem
    width: 100px
    background: linear-gradient(45deg, #525352, $marketgreen)
    background-size: 400% 100%
    animation: gradientAnimation 1s ease-in-out infinite
    border-radius: 5px

.light .inline-loader
    background: linear-gradient(45deg, #ffffff, $marketgreen )
    background-size: 400% 100%
    animation: gradientAnimation 1s ease-in-out infinite

@keyframes gradientAnimation 
  0% 
    background-position: 0% 30%
  
  100% 
    background-position: 100% 30%
