.create.main-container
    display: flex
    flex-direction: column
    justify-content: start
    padding-top: 10px
    @media screen and ( min-width: 769px )
        padding-top: 0  
        flex-direction: row
        justify-content: center
        align-items: start

.create .form-main-container
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    @media screen and ( min-width: 769px )
        flex-direction: row
        justify-content: center
        align-items: center

.progress-btn-container
    display: flex
    width: 100%

.form-progress-btn
    background-color: $marketgreen
    color: $darkviolet
    margin-right: 1.2rem

.form-progress-btn.back
    border: 1.5px solid $marketgreen
    background: none
    color: $marketgreen
    opacity: 0.9

.form-progress-btn.disabled
    opacity: 0.5

.form-tags-container
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly

.form-tag
    color: white
    background-color: $lightviolet
    border: 1px solid white
    width: 40%
    margin: 12px
    opacity: 0.9

.form-tag:hover
    opacity: 1

.form-tag.checked
    color: $darkviolet
    opacity: 1
    background-color: white
    border: 1px solid white

.brief-form-container
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: end
    background-color: $gray3
    height: 80%
    overflow-y: scroll
    padding: 25px 30px
    order: 1
    @media screen and ( min-width: 769px )
        height: 75%
        padding: 30px 50px
        width: 600px
        order: 2

.brief-form-container select
    margin-bottom: 1rem
    height: 2rem
    border-radius: 5px
    padding: 0 10px
    cursor: pointer
    border: 1px solid $gray4


.light .brief-form-container
    background-color: white

.brief-form
    width: 100%
    height: 100%

.brief-form h4 
    color: $darkwhite
    font-size: 1rem
    font-weight: 400
    margin-bottom: 1.2rem

.light .brief-form h4 
    color: $gray2

.brief-form .form-step
    display: flex
    flex-direction: column

.brief-form .form-step label
    display: flex
    flex-direction: column
    color: $marketgreen
    margin-bottom: 1.2rem
    font-size: 0.9rem

.light .form-step label 
    color: $gray3

.brief-form .form-step input 
    margin-top: 0.5rem
    height: 2rem
    padding: 0 10px

.light .form-step input 
    border: 1px solid $gray4


.brief-form .form-step textarea
    height: 200px
    margin-top: 1rem
    padding: 10px
    border: none

.light .brief-form .form-step textarea
    border: 1px solid $gray4

.progressbar-container
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 9px 8px
    width: 100%
    height: 48px
    border-radius: 24px
    order: 2
    margin-top: 20px
    background-color: $transparentwhite
    @media screen and ( min-width: 769px )
        order: 1
        width: 48px
        height: 75%
        flex-direction: column
        margin: 0
        margin-right: 30px

.light .progressbar-container
    background-color: white

.progressbar-container .step-container
    opacity: 0.3
    height: 32px
    width: 32px
    border-radius: 50%
    border: 1px solid $darkwhite
    color: $darkwhite
    display: flex
    justify-content: center
    align-items: center

.light .progressbar-container .step-container
    border: 1px solid $gray3
    color: $gray3

.progressbar-container .step-container p 
    font-size: 2rem

.step-container.active
    opacity: 1

.step-container.complete
    opacity: 1

.progressbar-container span
    height: 60px
    width: 1px
    opacity: 0.5
    border: 0.5px solid $sandbrown

.progressbar-container span.complete 
    opacity: 1
    border: 0.5px solid $ctagreen

.create-cards-container 
    display: flex
    flex-direction: column
    justify-content: start
    align-items: center
    width: 100%
    height: 100%
    overflow: scroll
    @media screen and ( min-width: 769px )
        flex-direction: row
        justify-content: center


.create-cards-container .create-card
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 20px
    background-color: $gray3
    color: white
    width: 80%
    margin: 20px
    text-align: center
    @media screen and ( min-width: 769px )
        width: 260px
        min-height: 370px


.light .create-cards-container .create-card
    background-color: white
    color: $gray3


.create-card h4 
    font-size: 1rem
    margin: 5px 0 10px 0

.create-card h5 
    font-size: 0.9rem
    margin: 10px 0

.create-card h6
    text-align: center
    font-size: 0.7rem
    opacity: 0.9

.create-card p
    font-size: 0.85rem
    text-align: left
    opacity: 0.9


.create-card ul 
    font-size: 0.85rem
    text-align: left
    margin-bottom: 20px
    opacity: 0.9

.create-card button 
    margin-top: 20px 

.create-card .price-line
    color: $gray4

.light .create-card .price-line
    color: $gray3

.create-card .disabled
    opacity: 0.5


.create-card button.disabled:hover
    border: 1px solid $ctablue
    cursor: auto

.create-user-info-container
    height: 150px
    width: 100%
    border-bottom:  1px solid $gray3
    color: white
    padding: 20px 0 60px 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    @media screen and ( min-width: 769px )
        border: none
        border-right: 1px solid $gray5
        height: 100%
        width: 280px
        padding: 0 40px 0 10px

.light .create-user-info-container
    border-bottom: 1px solid $gray5
    @media screen and ( min-width: 769px )
        border: none
        border-right: 1px solid $gray5


.create-user-info-container h4 
    margin-bottom: 10px
    text-align: center
    @media screen and ( min-width: 769px )
        margin-bottom: 15px

.create-user-info-container .sufficient
    color: $ctagreen

.create-user-info-container .insufficient
    color: red

.create-user-info-container .credit-info
    width: 80%
    display: flex
    flex-direction: column
    align-items: center
    @media screen and ( min-width: 769px )
        width: 90%
        margin: 40px 0


.create-user-info-container .credit-info p 
    font-size: 0.9rem
    opacity: 0.9

.create-user-info-container .credit-info a 
    width: 100%
    margin-top: 20px

.create-user-info-container .credit-info .btn
    width: 100%
    color: black
    // border: 1px solid white
    background-color: $marketgreen
    opacity: 0.8
    cursor: pointer

.create-user-info-container .credit-info .btn:hover
    opacity: 1

.form-step .file-upload-input-container
    // border: 1px solid red 
    width: 100%
    display: flex
    flex-direction: column
    align-items: start 
    font-size: 0.9rem
    margin-bottom: 20px
    color: $darkwhite

.form-step .file-upload-input-container input[type="file"] 
    display: none

.form-step .upload-inner-container
    display: flex
    justify-content: space-between
    padding: 0
    margin-top: 10px

.form-step .upload-inner-container label
    margin: 0
    font-family: $opensans
    color: $darkwhite
    text-align: center
    display: flex
    align-items: center
    border: 1px solid $gray4

.form-step .upload-inner-container label:hover
    color: $gray2
    background-color: $darkwhite

.light .form-step .upload-inner-container label
    color: $gray3

.form-step .upload-inner-container button
    margin-left: 20px
    background-color: $marketgreen
    color: $gray2 

.form-step .selected-file-container
    display: flex
    width: 100%
    align-items: center
        
.form-step .selected-file-container p 
    margin-right: 20px

.remove-file-btn
    padding: 7px 10px
    border-radius: 5px
    background: none
    border: 1px solid $marketgreen
    color: $darkwhite
    display: flex
    align-items: center 
    cursor: pointer

.light .remove-file-btn
    color: $gray3

.get-credits-btn
    text-align: center
    font-size: 0.8rem
    font-weight: 500