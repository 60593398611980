.topbar-main-container
    display: flex
    position: relative
    height: 60px
    width: 100%
    justify-content: space-between
    align-items: center
    padding: 0 5px 0 15px
    background-color: $gray1
    @media screen and ( min-width: 769px )
        display: none

.topbar-main-container.light
    background-color: white


.menu-btn-container
    height: 2rem
    width: 2rem
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    color: $darkwhite
    font-size: 1.2rem

.menu-btn-container.light
    color: $gray2

.mobilemenu-main-container
    width: 100%
    height: calc( 100vh - 60px )
    // height: 100vh
    z-index: 9
    top: 60px
    background-color: $gray1
    position: fixed
    right: -100vw
    opacity: 0
    transition:  right 0.2s ease-in-out, opacity 0.2s ease-in-out
    padding: 25px
    padding-bottom: 50px
    @media screen and ( min-width: 769px )
        display: none

.mobilemenu-main-container.visible
    right: 0
    opacity: 1

.mobilemenu-main-container.light
    background-color: white

.mobile-nav
    list-style: none
    height: 100%
    display: flex
    flex-direction: column
