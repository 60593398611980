.team h5
    font-size: 1rem
    color: $darkwhite
    margin-top: 18px
    font-weight: 400

.light .team h5
    color: $gray2

.login .header
    margin-bottom: 20px