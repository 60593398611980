.chat.main-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    color: $darkwhite
    @media screen and (min-width: 769px)
        flex-direction: row

.light .chat.main-container
    color: $gray2

.chat .settings-button
    background-color: $gray3

.light .chat .settings-button
    background-color: white

.chat .settings-button p 
    height: 0.9rem

.chat .incoming 
    opacity: 0.3

.chat-sidebar-container
    width: 100%
    position: relative
    padding: 20px 15px
    @media screen and (min-width: 769px)
        width: 350px
        height: 100%

.chat .chat-inner-container
    height: 100%
    width: 100%
    padding: 15px
    display: flex
    flex-direction: column
    @media screen and (min-width: 769px)
        padding-left: 40px

.chat span 
    height: 0.5px
    width: 100%
    background-color: $gray4
    @media screen and (min-width: 769px)
        height: 100%
        width: 0.5px

.light .chat span 
    background-color: $gray3

.chat .threads-list-header
    display: flex
    align-items: center
    justify-content: space-between

.chat .thread-list
    padding: 15px

.chat .thread-list .list-header
    height: 40px

.chat .thread-list ul 
    height: 100%
    overflow: scroll

.chat .thread-list h4 
    margin-bottom: 15px

.avatar-chat-preview-card
    width: 100%
    padding: 10px 0
    border-bottom:  0.5px solid $gray4
    opacity: 0.75
    cursor: pointer

.avatar-chat-preview-card:hover
    opacity: 1

.thread-list .hidden
    display: none
    @media screen and (min-width: 769px)
        display: block

.thread-list h4 
    text-align: center


.chat .chat-header-container
    width: 100%
    height: 40px
    font-weight: 500

.chat-header-container .inner
    height: 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center


.chat .chat-thread-container
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    position: relative
    // overflow: scroll

.chat-thread-container .chat-container
    position: absolute
    padding: 5px 0
    width: 100%
    height: 100%
    overflow: scroll
    display: flex
    flex-direction: column-reverse
    // justify-content: end


.chat .chat-input-container
    width: 100%
    height: 3rem
    bottom: 0
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 10px
    background-color: $gray3
    border-radius: 1.5rem 
    margin-top: 10px

.light .chat .chat-input-container
    background-color: $gray5

.chat .chat-input-container form 
    width: 100%

.chat .chat-input-container input 
    width: 100%
    background: none
    color: white
    padding: 0 5px

.light .chat-input-container input 
    color: $gray1

.chat .chat-input-container button 
    height: 1.8rem
    width: 1.8rem
    display: flex
    border: none
    border-radius: 50%
    align-items: center
    justify-content: center
    font-size: 0.9rem
    color: white
    cursor: pointer

.chat-input-container .submit
    opacity: 0.7
    background-color: $gray2

.chat-input-container .submit.filled
    opacity: 1

.chat-input-container .record
    background-color: $gray2
    margin-left: 0.3rem


.chat-input-container .attachments
    background: none
    margin: 0 0.3rem


.light .chat-input-container button 
    background-color: white
    color: $gray3

.chat .chat-input-container button:hover
    opacity: 1

.chat-thread-container .message-container
    width: 100%


.chat-thread-container .message-container .inner-div
    width: 80%
    padding: 15px
    border-radius: 10px
    background-color: $gray7
    color: $darkwhite
    margin-bottom: 1rem 


.chat-thread-container .sent .inner-div
    margin-left: auto
    background-color: $gray1
    text-align: right

.light .message-container .inner-div
    background-color: $darkwhite
    color: $gray3

.chat-thread-container .received .inner-div
    position: relative

.chat-container .chat-loader
    display: block
    border-radius: 10px
    margin-bottom: 1rem 
    height: 2.5rem
    width: 40%
    background: linear-gradient(270deg, #f6f6f6d0, #dedede7c, #e2e2e2a9)
    background-size: 600% 600%
    animation: gradient-animation 2s ease infinite

@keyframes gradient-animation 
  0% 
    background-position: 0% 50%
  
  50% 
    background-position: 100% 50%
  
  100% 
    background-position: 0% 50%


.chat .start-chatting-message
    width: 100%
    display: flex
    justify-content: center
    margin-bottom: 1rem 

.message-container .inner-div p 
    margin-bottom: 0.5rem

.message-container .inner-div ul 
    margin-left: 2rem 

.message-container .inner-div ul li  
    margin-bottom: 0.5rem

.chat .new-thread-btn
    cursor: pointer
    font-size: 1.1rem
    opacity: 0.9

.chat .new-thread-btn:hover
    opacity: 1


