.footer-main-container
    width: 100vw
    height: 30px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 5px 25px
    background-color: $gray1
    color: $gray5
    font-size: 0.9rem

.footer-main-container a 
    color: $gray5

.footer-main-container.light
    background-color: white
    color: $gray2

.footer-main-container.light a 
    color: $gray2