.view-container.login
    display: flex
    height: calc( 100vh -  30px )
    justify-content: center
    align-items: center
    background-color: $gray2

.login-form-container
    width: 90%
    background-color: $gray3
    display: flex
    flex-direction: column
    justify-content: start
    align-items: center
    color: $darkwhite
    padding: 30px
    @media screen and (min-width: 769px)
        width: 450px

.login-form-container .img-container
    height: 1.6rem

.login-form-container .img-container img 
    height: 100%

.login-form-container h3 
    font-size: 1.2
    font-weight: 500
    margin: 15px 0 10px 0
    color: $darkwhite

.login-form-container .login-form
    display: flex
    flex-direction: column
    width: 100%

.login-form-container .login-form label 
    display: flex
    flex-direction: column
    color: $darkwhite
    margin-bottom: 1rem
    font-size: 0.9rem

.login-form-container .login-form input 
    margin-top: 0.5rem
    height: 2rem
    padding: 0 10px
    background-color: $darkwhite
    color: black


.login-form-container .login-footer
    display: flex
    justify-content: center
    margin: 10px 0

.login-footer span
    margin-left: 0.5rem
    font-weight: 500
    cursor: pointer

.login-form-divider
    width: 100%
    margin: 20px 0
    display: flex
    align-items: center
    justify-content: center

.login-form-divider span
    height: 1px
    width: 40%
    margin: 0 10px
    background-color: $gray4

.login-form .login-btn.disabled
    opacity: 0.5

.login-form .login-btn.disabled:hover
    opacity: 0.5

.login-form-container .google-signin
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    opacity: 1
    background-color: white
    color: $darkviolet

.login-form-container .google-signin:hover
    opacity: 0.9

.login-form-container .login-btn
    width: 100%
    background-color: $marketgreen
    color: black
    margin-top: 15px 
    opacity: 0.9

.login-form-container .login-btn:hover
    opacity: 1

.login-form-container .google-signin svg
    margin-left: 0.5rem


.login .invalid
    display: flex
    flex-direction: column
    align-items: center
    color: $darkwhite
    padding: 0 40px
    text-align: center

.login .invalid h3 
    margin-bottom: 2rem
    color: $darkwhite

.login-form-container a 
    color: $darkwhite
    width: 100%
    text-align: center
    margin-top: 20px

