//FONTS
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')

$opensans: "Open Sans", sans-serif

//RESET STYLES
*
    margin: 0
    padding: 0
    box-sizing: border-box
    -ms-overflow-style: none
    scrollbar-width: none

*::-webkit-scrollbar
    display: none

a
    text-decoration: none

body
    font-family: $opensans

textarea, input
    border: none

textarea, input:focus
    border: none
    outline: none     

input, textarea 
    font-family: $opensans

textarea:placeholder
    font-family: $opensans

input:placeholder
    font-family: $opensans


//FILE IMPORTS
@import ./variables
@import ./alert
@import ./views
@import ./sidebar
@import ./library
@import ./loader
@import ./create
@import ./avatarDetail
@import ./login
@import ./community
@import ./topbar
@import ./inputs
@import ./items
@import ./team
@import ./settings
@import ./orders
@import ./chat
@import ./footer
@import ./policies
@import ./admin
@import ./onboarding
@import ./prompts
@import ./credits