.prompts.main-container
    display: flex
    flex-direction: column
    justify-content: start

.prompts .prompt-bar
    padding: 10px 
    display: flex
    justify-content: space-evenly
    align-items: center
    border: 0.5px solid rgba( 255, 255, 255, 0.4)
    flex-wrap: wrap
    color: $darkwhite
    @media screen and (min-width: 769px)
        padding: 10px 15px
        justify-content: start

.light .prompt-bar
    color: $gray3
    border: 0.5px solid $gray3


.prompts .prompt-tag
    padding: 5px 10px
    background-color: $gray3
    border: 1px solid $gray3
    color: $darkwhite
    font-size: 0.8rem
    border-radius: 5px
    margin: 8px
    cursor: pointer
    opacity: 0.8
    width: auto

.light .prompt-tag
    background-color: $gray5
    border: 1px solid $gray5
    color: $gray2

.prompts .prompt-tag:hover
    opacity: 1

.prompts .prompt-tag.active
    opacity: 1
    border: 0.5px solid $darkwhite

.light .prompt-tag.active
    opacity: 1
    border: 0.5px solid $gray1

.prompt-cards-container
    // border: 1px solid yellow
    height: calc( 100% - 100px )
    overflow: scroll
    padding-bottom: 20px 
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    justify-items: start
    width: 100%

.prompts .prompt-card
    padding: 15px
    width: 100%
    position: relative
    @media screen and (min-width: 769px)
        width: 50%

.prompt-card .prompt-card-inner
    background-color: $gray3
    padding: 10px
    font-size: 0.9rem
    position: relative
    @media screen and (min-width: 769px)
        padding: 15px

.light .prompt-card .prompt-card-inner
    background-color: white
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1)

.prompt-card-inner h4 
    font-size: 0.95rem
    color: $darkwhite
    margin-bottom: 0.8rem

.light .prompt-card-inner h4 
    color: $gray3

.prompt-card-inner p 
    color: $gray5

.light .prompt-card-inner p 
    color: $gray3

.prompt-icon-container
    position: absolute

.prompt-icon-container.copy-icon
    top: 15px
    right: 15px

.prompt-icon-container.expand-icon
    bottom: 3px
    right: 50%

.prompt-icon
    font-size: 1rem
    cursor: pointer
    color: $darkwhite


.light .prompt-icon
    color: $gray3

.prompt-icon:hover
    scale: 1.05

.copied-tag
    background-color: $marketgreen
    color: $gray3
    padding: 5px
    border-radius: 5px
    position: absolute
    top: 20px
    right: 20px
    opacity: 0
    z-index: 100
    font-size: 0.9rem
    transition: opacity 0.1s ease-in-out
    cursor: pointer

.copied-tag.reveal
    opacity: 1

//TAG REVEAL

.light.copied-tag
    opacity: 0.9rem
    right: 0.5 px
    position: absolute
    display: flex
    justify-content: center
    align-items: start

.copied-tag-light
    animation: opacity 1s ease-in-out
    display: flex   
    justify-content: center
    align-items: center
    margin-top: 0px 5px 8px 0
    height: 100px
    width: 100px
    background-color: $marketgreen
    border: 1px solid green

.copied-tag-light:hover
    opacity: 1
    scale: 1.02
    display: flex
    justify-content: center
    align-items: start
    
.prompt-expand-icon-container
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    margin: 10px 0 0px 0
    cursor: pointer

// .prompt-toggle-container
//     width: 100%
//     display: flex
//     justify-content: flex-end
//     align-content: center
//     padding: 15px

// .prompts .toggle-btn
//     justify-content: flex-start
//     background: #333333
//     opacity: 0.9
//     cursor: pointer
//     height: 1.2rem
//     width: 2.6rem
//     border: none
//     padding: 0.1rem
//     display: flex
//     align-items: center
//     border-radius: 0.6rem
//     margin: 0 0.5rem

// .light .prompts .toggle-btn
//     background: #c6c6c6

// .prompts .toggle-btn span
//     background-color: #e8e8e8
//     height: 0.9rem
//     width: 0.9rem
//     display: inline-block
//     border-radius: 50%

// .light .prompts .toggle-btn span
//     background-color: white


.prompts .toggle-btn-container
    width: 100%
    display: flex
    align-items: center
    justify-content: end
    // border: 1px solid red
    padding: 15px

.prompts .toggle-btn-container p 
    color: $darkwhite
    opacity: 1

.light .prompts .toggle-btn-container p 
    color: $gray2

.prompts .toggle-btn-container p.inactive
    opacity: 0.5

.prompts .toggle-btn-container .bold
    font-weight: 500
    margin-right: 1rem


.prompts .toggle-btn
    justify-content: flex-start
    background: black
    opacity: 0.9
    cursor: pointer
    height: 1.2rem
    width: 2.6rem
    border: none
    padding: 0.1rem
    display: flex
    align-items: center
    border-radius: 0.6rem
    margin: 0 0.5rem

.prompts .toggle-btn.active
    justify-content: flex-end

.prompts .toggle-btn span 
    background-color: $gray5
    height: 0.9rem
    width: 0.9rem
    display: inline-block
    border-radius: 50%

.prompts .toggle-btn.default 
    justify-content: flex-end

.light .prompts .toggle-btn
    background: $gray4

.light .prompts .toggle-btn span 
    background-color:  white


.prompts .toggle-btn:hover
    opacity: 1





