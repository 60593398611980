.orders .inner-container
    display: flex
    @media screen and ( min-width: 769px )
        width: 75%

.orders .inner-container .orders-container
    width: 100%
    color: $darkwhite

.orders .inner-container ul 
    width: 100%
    overflow: scroll
    margin-top: -10px

.orders .inner-container li 
    display: flex
    width: 100%
    text-align: center
    padding: 10px 0
    border-bottom: 0.5px solid $gray4
    font-size: 0.9rem
    font-weight: 300

.orders .inner-container .header
    font-size: 1rem
    font-weight: 500

.orders .orders-container .item 
    display: flex
    justify-content: center
    align-items: center

.orders .orders-container .item.l
    width: 40%

.orders .orders-container .item.m
    width: 30%

.orders .orders-container .item.s
    width: 20%

.orders .orders-container .item.xs
    width: 10%

.orders-container .item a 
    width: 100%
    height: 100%
    border: 1px solid white
    border: none
    color: white
    opacity: 0.8

.light .orders-container .item a 
    color: $gray3

.orders-container .item a:hover 
    opacity: 1
    
.orders-container .item span 
    height: 0.5rem
    width: 0.5rem
    border-radius: 50%
    background-color: $sandbrown
    margin-right: 5px

.orders-container .item .complete
    background-color: $marketgreen