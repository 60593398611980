//COLORS
$darkviolet: #0D002A
$lightviolet: #001439
$sandbrown: #e5b18f
$lightbrown: #e9b59387
$lightwhite: #ffffff42
$lightwhite2: #ffffff1c
$ctagreen: #19D000
$ctablue: #003EAD
$transparentwhite: #ffffff22
$staryellow: #ffef5e
$gray1: #252525
$gray2: #333333
$gray3: #525352
$darkwhite: #f4f4f4
$marketgreen: #CDEE48
$gray4: #c6c6c6
$gray5: #e8e8e8
$gray6: #dfdfdf
$gray7: #444444
// $golden:#e5b18f
// $golden: #DCFF50
$tomato: #dc7a46


//GENERAL
.div-shadow
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1)
    
.round-div
    border-radius: 10px

.btn
    cursor: pointer
    padding: 7px 10px
    border: none
    border-radius: 5px
    @media screen and (min-width: 769px)
        padding: 10px 20px

.main-btn
    color: $darkwhite
    border: 1px solid $marketgreen
    background-color: $gray3
    width: 100%

.avatar-card .main-btn
    margin-top: 20px
    @media screen and (min-width: 769px)
        margin-top: 0

.light .main-btn
    color: $gray2
    background-color: white

.main-btn:hover
    color: black
    background-color: $marketgreen

.shadow-btn
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.35)

.round-btn
    border-radius: 5px

ul 
    list-style-type: none
    padding: 0
    margin: 0

.submit-btn
    color: white
    background-color: $ctablue
    border: 1px solid  $ctablue

.submit-btn:hover
    border: 1px solid  white

.mobile 
    display: block
    @media screen and (min-width: 769px)
        display: none

.desktop 
    display: none
    @media screen and (min-width: 769px)
        display: block

    

