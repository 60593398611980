.avatar-cards-container
    height: 100%
    display: flex
    flex-wrap: nowrap
    overflow: scroll
    flex-direction: column
    padding-bottom: 40px
    @media screen and (min-width: 769px)
        flex-wrap: wrap
        flex-direction: row
        justify-content: start
        align-content: flex-start     

.avatar-card-container
    padding: 15px
    height: 190px
    @media screen and (min-width: 769px)
        width: 25%
        height: 380px
    @media (min-width: 1600px)
        width: 20%
    
.avatar-card-container.company
    height: 180px
    @media screen and (min-width: 769px)
        height: 200px

.avatar-card
    height: 100%
    width: 100%
    position: relative
    background-color: $gray3
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    color: $darkwhite
    padding: 15px
    @media screen and (min-width: 769px)
        padding: 30px
        flex-direction: column
        justify-content: space-between

.avatar-card.light
    background-color: white

.avatar-card a 
    width: 100%

.avatar-card-img-container
    display: none
    @media screen and (min-width: 769px)
        display: flex
        flex-direction: column
        align-items: center
        width: 40%

.avatar-card-img-container img
    width: 100%
    border-radius: 50%

.avatar-card-snapshot-container
    width: 40%
    margin-left: 5px
    // height: 100%
    overflow: scroll
    @media screen and (min-width: 769px)
        // padding: 5px 0
        width: 100%

.avatar-card-snapshot-container span
    font-weight: 400
        
.avatar-card-snapshot-container h4 
    display: none
    @media screen and (min-width: 769px)
        display: block

.avatar-card-img-container h4 
    display: block
    margin-top: 10px
    @media screen and (min-width: 769px)
        display: none

.avatar-card h4 
    color: $darkwhite
    font-weight: 400
    margin-bottom: 0.3rem
    font-size: 1rem
    @media screen and (min-width: 769px)

.light .avatar-card p
    color: $gray2

.light .avatar-card h4
    color: $gray1

.company-details-container
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.company-details-container h4 
    text-align: center
    color: white
    font-size: 1rem
    font-weight: 400
    margin-bottom: 0.3rem

.light h4, h3, h2
    color: $gray1

.light p 
    color: $gray1

.avatar-card-snapshot-container p 
    font-size: 0.85rem
    font-weight: 300

.avatar-card-btn-container
    width: 25%
    margin-left: 1rem
    @media screen and (min-width: 769px)
        margin-left: 0
        width: 100%

.favorite-icon-container
    color: $staryellow
    position: absolute
    top: 18px
    right: 30px
    font-size: 1.4rem
    opacity: 0.6
    transition: scale 0.2 ease-in
    cursor: pointer
    @media screen and (min-width: 769px)
        right: 18px
        top: 15px

.light .favorite-icon-container
    color: $sandbrown

.favorite-icon-container.favorite
    opacity: 0.85

.favorite-icon-container:hover
    opacity: 1
    scale: 1.05

.filter-bar-container
    width: 100%
    padding: 5px 15px
    padding-top: 0
    @media screen and (min-width: 769px)
        padding-top: 15px

.filter-bar
    padding: 10px
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    color: $darkwhite
    border: 0.5px solid rgba( 255, 255, 255, 0.4)
    @media screen and (min-width: 769px)
        flex-direction: row
        justify-content: space-evenly
        align-items: center

.light .filter-bar
    color: $gray3
    border: 0.5px solid $gray3

.filter-bar div 
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding: 8px 0
    @media screen and (min-width: 769px)
        width: auto
        padding: 0


.filter-bar.hidden .expansible 
    display: none
    @media screen and (min-width: 769px)
        display: flex
        cursor: pointer

.filter-bar span
    display: none
    @media screen and (min-width: 769px)
        display: block
        height: 20px
        width: 1px
        background-color: $gray5
        opacity: 0.3

.filter-bar svg 
    font-size: 0.9rem
    margin-right: 0.5rem

.filter-bar label 
    margin-right: 0.5rem

.filter-bar select
    cursor: pointer
    height: 1.2rem
    border-radius: 4px
    padding: 0 10px
    cursor: pointer
    border: 1px solid $gray3
    background: $gray3
    color: white
    width: 50%
    @media screen and (min-width: 769px)
        width: 150px

.light .filter-bar select
    background: none
    color: $gray3


.filter-bar select:focus
    outline: none

.filter-bar input
    margin-left: 0.5rem
    scale: 1.2
    cursor: pointer

.filter-bar .chevron
    margin-top: 1.2rem
    background-color: rgba( 255, 255, 255, 0.05)
    padding: 4px
    border-radius: 15px
    @media screen and (min-width: 769px)
        display: none !important

.product-pending-message-container
    width:  100% 
    padding: 5px 15px

.product-pending-message-container .message-container
    width:  100% 
    border: 0.5px solid $marketgreen
    padding: 10px
    display: flex
    justify-content: center
    align-items: center
    color: $darkwhite
    font-size: 0.9rem 
    text-align: center

.product-pending-message-container .message-container a 
    text-decoration: underline
    color: white
    margin-left: 0.3rem

.light .product-pending-message-container .message-container a 
    color: $gray1

.avatar-card .editable-div
    display: flex
    align-items: center
    margin-bottom: 0.5rem

.avatar-card .editable-div h4 
    margin: 0
    font-size: 1.1rem

.avatar-card .editable-div input 
    width: 65%
    font-size: 1.1rem
    margin: 0
    border: none
    background: none
    border-bottom: 0.5px solid $marketgreen
    color: $darkwhite

.light .avatar-card .editable-div input 
    color: $gray3

.avatar-card .edit-icon
    color: $marketgreen
    font-size: 0.8rem
    margin-left: 1rem
    cursor: pointer

.light .avatar-card .edit-icon
    color: $gray3

.avatar-card .edit-icon.red 
    color: $tomato

.no-data-message .activation-input
    border: 1px solid $marketgreen
    height: 2rem
    margin-top: 0.2rem
    border-radius: 5px
    background: none
    text-align: center
    background-color: $gray2
    color: $darkwhite
    width: 6rem
    font-weight: 600

.light.no-data-message .activation-input
    background-color: white
    color: $gray3
    border: 2px solid $marketgreen




