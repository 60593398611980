.settings .inner-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-content: flex-start
    justify-content: start

.settings-button-container
    width: 50%
    height: 170px
    padding: 10px
    @media screen and (min-width: 769px)
        width: 33%

.settings-button-container a 
    width: 100%
    height: 100%

.settings-button-container .settings-button
    width: 100%
    height: 100%
    cursor: pointer
    color: $darkwhite
    display: flex
    flex-direction: column
    justify-content: center
    align-self: center
    text-align: center
    padding: 8px
    opacity: 0.8

.settings-button-container .settings-button:hover
    opacity: 1

.settings-button-container .settings-button svg
    font-size: 1.4rem

.settings-button-container .settings-button h4 
    font-size: 1rem
    margin-top: 5px
    color: white

.settings-button-container .settings-button p  
    height: 30%
    font-size: 0.85rem
    color: $gray5
    margin-top: 0.5rem

.light .settings-button-container .settings-button svg
    color: $gray3

.light .settings-button-container .settings-button h4 
    color: $gray2

.light .settings-button-container .settings-button p  
    color: $gray3