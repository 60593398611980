.admin .inner-container
    color: $darkwhite
    font-size: 1rem

.admin h5 
    font-size: 1rem
    margin-top: 1rem

.admin-item .size-l
    width: 85%

.admin-item .size-m
    width: 70%

.admin-item .size-s
    width: 15%
    display: flex
    justify-content: center

.admin.light .inner-container
    color: $gray3

.admin .item-list-container

.admin .admin-item
    display: flex
    // justify-content: space-between
    flex-direction: column
    // justify-content: center
    // align-items: center
    border-bottom: 0.5px solid $gray4 
    padding: 5px 0

.admin-item .admin-inner-container
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%

.admin .toggle-btn
    justify-content: flex-start
    background: $gray2
    opacity: 0.8
    cursor: pointer
    height: 1.2rem
    width: 2.6rem
    border: none
    padding: 0.1rem
    display: flex
    align-items: center
    border-radius: 0.6rem

.admin.light .toggle-btn
    background: $gray3

.admin .toggle-btn.active 
    justify-content: flex-end

.admin .toggle-btn:hover
    opacity: 1

.admin .toggle-btn span 
    background-color: $gray4
    height: 0.9rem
    width: 0.9rem
    display: inline-block
    border-radius: 50%

.admin .toggle-btn.active span 
    background-color: $marketgreen

.admin-item input
    text-align: center
    background: none
    color: $darkwhite
    width: 100%
    border: none

.light .admin-item input
    color: $gray2


.admin-item .credits-input-div
    background-color: $gray2
    opacity: 0.8
    border-radius: 5px
    padding: 0 10px
    display: flex
    align-items: center

.admin-item form
    display: flex

.light .credits-input-div
    background-color: white
    color: $gray3
    border: 0.5px solid $gray3

.admin-item .confirm-btn
    color: $marketgreen

.light .admin-item .confirm-btn
    color: $gray3

.admin-item .deny-btn
    color: $tomato
    margin-left: 0.5rem
    
.admin-item .credit-btn
    cursor: pointer

.admin-item .more
    display: flex
    align-items: center

.admin-item .more svg
    margin-right: 0.5rem
    font-size: 0.9rem
    cursor: pointer
    
.admin-item .admin-accounts-detail
    border: 1px solid red 


.item-list-container .admin-item-header
    border: 1px solid red

.admin-item.header
    width: 100%
    display: flex
    flex-direction: row

.admin-item .admin-detail
    padding-left: 2.5rem

.admin-item .admin-detail .admin-users-list
    padding-left: 0.5rem

