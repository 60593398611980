.marketsauce-alert-container
    background: $darkwhite
    color: $gray2

.marketsauce-alert-container .alert-icon
    color: $gray3
    border: 3px solid $gray3

.marketsauce-alert-container .confirm-btn
    background-color: $marketgreen
    border: 1px solid $marketgreen
    color: $gray1

.marketsauce-alert-container .deny-btn
    background-color: $darkwhite
    color: $gray1
    border: 1px solid $marketgreen
    margin-right: 15px

.marketsauce-alert-container .order1
    order: 1

.marketsauce-alert-container .order2
    order: 2