.onbaording-widget-main.hidden
    opacity: 0
    right: -50vw
    transition: right 1s ease-in-out

.onbaording-widget-main
    background-color: rgba(0, 0, 0, 0.8)
    position: fixed
    z-index: 8
    bottom: 60px
    right: 40px
    cursor: pointer
    opacity: 1
    transition: right 0.5s ease-in-out

.light.onbaording-widget-main
    background-color: rgba(0, 0, 0, 0.2)

.onbaording-widget-main.expanded
    bottom: 0
    right: 0
    height: 100vh
    width: 100vw
    padding: 20px
    cursor: default
    transition: none
    @media screen and (min-width: 769px)
        padding: 50px

.onboarding-inner-div
    background-color: $gray2
    color: $darkwhite
    padding: 10px 20px
    border: 1px solid $marketgreen
    height: 100%
    @media screen and (min-width: 769px)
        padding: 20px 25px

.light .onboarding-inner-div
    background-color: white
    color: $gray3
    border: 1px solid $gray4

.onboarding-inner-div h4 
    margin-bottom: 0.5rem

.expanded .onboarding-inner-div
    border: none 

.onboarding-inner-div .widget-btn
    margin-top: 1.5rem
    width: 100%
    background-color: $marketgreen
    font-weight: 600
    color: $gray2

.onboarding-inner-div .widget-btn:hover
    scale: 1.02

.onboarding-inner-div .close-btn
    color: $darkwhite
    position: absolute
    font-size: 1.3rem
    cursor: pointer
    top: 45px
    right: 45px
    @media screen and (min-width: 769px)
        top: 70px
        right: 75px

.light .onboarding-inner-div .close-btn
    color: $gray2

.onboarding-inner-div .onboarding-guide
    display: flex
    flex-direction: column
    @media screen and (min-width: 769px)
        flex-direction: row
        height: 100%

.onboarding-guide .steps-main-container
    height: auto
    width: 100%
    padding: 15px
    margin-top: 50px
    @media screen and (min-width: 769px)
        display: flex
        flex-direction: column
        justify-content: center

        margin-top: 0
        width: 35%
        height: 100%
        padding: 30px 20px 20px 20px

.onboarding-guide  h3 
    margin-bottom: 20px
    color: $darkwhite
    text-align: center

.light .onboarding-guide h3
    color: $gray2

.onboarding-guide .video-main-container
    height: 50vh
    width: 100%
    padding: 15px
    
    @media screen and (min-width: 769px)
        display: flex
        flex-direction: column
        justify-content: center
        width: 65%
        height: 100%
        padding: 30px 20px 20px 20px


.onboarding-step-card
    background-color: $gray3
    color: $darkwhite
    padding: 15px
    margin-bottom: 15px
    scale: 1
    width: 100%

.light .onboarding-step-card
    background-color: $darkwhite
    color: $darkwhite

.onboarding-step-card p  
    margin: 1rem 0 

.onboarding-guide .onboarding-step-btn
    background-color: $marketgreen
    font-weight: 600

.onboarding-guide .onboarding-step-btn:hover
    scale: 1.03

.onboarding-step-card.inactive
    display: none
    @media screen and (min-width: 769px)
        display: inline-block
        scale: 0.9
        opacity: 0.5

.onboarding-guide .step-main
    display: flex
    align-items: center

.onboarding-guide .onboarding-chevron
    display: none
    @media screen and (min-width: 769px)
        display: inline-block
        color: $darkwhite
        font-size: 1.5rem
        margin-left: 0.5rem

.light .onboarding-guide .onboarding-chevron
    color: $gray3

.onboarding-step-card .title-container
    display: flex
    align-items: center

.onboarding-step-card .title-container .step-checkmark 
    color: $marketgreen
    margin-right: 0.8rem

.light .title-container .step-checkmark 
    color: $gray2

.onboarding-step-card .title-container h4 
    margin: 0

.video-main-container .onboarding-video-container
    width: 100%

.video-main-container .onboarding-video-container iframe
    border: none
    width: 100%
    border-radius: 10px
    @media screen and (min-width: 769px)
        height: 60vh





