.item-list-container
    width: 100%
    margin-top: 10px

.item-row
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 20px
    color: $gray1
    background-color: $gray4
    margin-top: 15px

.item-row.trimmed
    border-bottom-right-radius: 0

.light .item-row
    background-color: $gray5
    color: $gray1 

.item-row .btn-container
    display: flex

.item-btn
    cursor: pointer

.item-row .btn-container .item-btn
    padding: 3px
    margin-left: 10px
    font-size: 1rem

.campaign-main-container
    width: calc( 100% - 25px )
    margin-left: 25px

.subitem-row
    width: 100%
    border: none
    border-left: 1px solid $gray5
    border-right: 1px solid $gray5
    width: calc( 100% - 30px )
    margin-left: 30px
    padding: 10px 20px
    color: $darkwhite
    display: flex
    justify-content: space-between
    align-items: center
    @media screen and (min-width: 769px)
        width: calc( 100% - 50px )
        margin-left: 50px

.subitem-row.last
    border-bottom: 1px solid $gray5
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px


.light .subitem-row
    color: $gray3

.subitem-row .btn-container
    display: flex
    align-items: center

.subitem-row .btn-container .item-btn
    padding: 3px
    margin-left: 10px
    cursor: pointer
    font-size: 1rem

.subitem-row.title h4 
    font-weight: 500

.subitem-row.title
    color: $gray1
    border-bottom: 1px solid $gray5
    background-color: $gray6


.light .subitem-row.title
    color: $gray1
    background-color: $darkwhite

.item-row input
    border: none
    background: none
    border-bottom: 0.5px solid white
    font-size: 0.9rem
    color: $gray3
    font-style: italic

.light .item-row input
    border-bottom: 0.5px solid $gray3

.subitem-row input
    border: none
    background: none
    border-bottom: 0.5px solid white
    font-size: 0.9rem
    color: $gray4
    font-style: italic

.light .subitem-row input
    color: $gray3
    border-bottom: 0.5px solid $gray3

.new-campaign .item-btn
    color: $marketgreen