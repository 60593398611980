.view-container.policy
    background-color: $gray2
    color: $darkwhite
    display: flex
    flex-direction: column
    height: 100vh
    width: 100vw
    padding: 50px 10vw
    overflow: scroll

.view-container.policy h1 
    margin-bottom: 3rem 

.view-container.policy p 
    margin-bottom: 1rem 


.view-container.policy.light
    background-color: white
    color: $gray2

.view-container.policy h2 
    color: $darkwhite

.view-container.policy.light h2 
    color: $gray3