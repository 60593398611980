.sidebar-main-container
    height: 50px
    background-color: $gray1
    transition: width 0.2s ease-in-out
    position: relative
    display: none
    @media screen and (min-width: 769px)
        height: 100%
        display: block

.sidebar-main-container.light 
    background-color: white

.sidebar-main-container.expanded
    width: 220px

.sidebar-main-container.shrink
    width: 60px

.sidebar-toggle-btn
    height: 30px
    width: 30px
    display: none
    align-items: center
    justify-content: center
    font-size: 0.9rem
    background-color: $gray3
    color: $darkwhite
    border-radius: 50%
    padding: 0
    position: absolute
    top: 45%
    right: -30px
    transition: scale 0.1s ease
    z-index: 2
    @media screen and (min-width: 769px)
        display: flex

.sidebar-toggle-btn:hover
    scale: 1.05

.sidebar-toggle-btn.light
    background-color: white
    color: $marketgreen

.nav-container-desktop
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    padding: 40px 0px 40px 10px
    font-size: 0.9rem

.nav-container-desktop ul
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: start

.nav-container-desktop ul li 
    width: 100%

.nav-btn
    display: flex
    align-items: center
    padding: 0px 12px
    height: 40px
    background-color: $gray2
    border: 1px solid $gray2
    color: $darkwhite
    margin-bottom: 8px

.nav-btn:hover
    color: white

.nav-btn.light
    background-color: white
    border: 1px solid $marketgreen
    color: $gray3

.nav-btn.light:hover
    color: $gray1

.nav-btn.mobile
    justify-content: center
    background: none
    border: none

.nav-btn.centered
    justify-content: center


.nav-btn svg 
    font-size: 0.9rem

.active .nav-btn
    background-color: $gray3
    border: 1px solid $gray3

.active .nav-btn.light
    background-color: $marketgreen
    border: 1px solid $marketgreen
    color: white

.nav-btn p 
    margin-left: 0.8rem
    overflow: hidden

.nav-btn.mobile p 
    margin-left: 0

.nav-logo-container
    width: 100%
    display: flex
    justify-content: center

.nav-logo-container.desktop
    margin-bottom: 25px

.nav-logo-container img
    height: 24px
    overflow: hidden

.nav-container-desktop .full-width
    width: auto

.nav-container-desktop .no-width
    width: 0

.sign-out-btn
    display: flex
    align-items: center
    justify-content: start
    padding: 0px 12px
    height: 40px
    width: 100%
    background: none
    border: 2px solid white
    color: $darkwhite
    margin-bottom: 8px
    margin-top: 30px
    

.sign-out-btn.mobile
    justify-content: center

.sign-out-btn:hover
    color: white

.sign-out-btn.light
    border: 2px solid $gray3
    color: $gray3

.sign-out-btn.light:hover

.sign-out-btn p 
    margin-left: 0.8rem
    overflow: hidden

.sign-out-btn svg
    font-size: 0.9rem

.dark-mode-btn
    margin-top: 30px
    cursor: pointer

.nav-container-desktop .botom-options
    width: 100%
    margin-top: auto
