.avatar-datail-container
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    overflow: scroll
    @media screen and (min-width: 769px)
        flex-direction: row

.detail-sidebar-container
    padding: 15px
    height: null
    width: 100%
    z-index: 3
    color: white
    display: flex
    flex-direction: column
    justify-content: flex-start
    @media screen and (min-width: 769px)
        height: calc( 100% - 70px )
        width: 250px

.detail-sidebar-container .upper-container
    height: calc( 100% - 80px )

.detail-sections-container
    height: 90vh
    width: 100%
    padding: 15px
    display: flex
    @media screen and (min-width: 769px)
        height: 100%
        width: calc( 100% - 250px )

.detail-sections-container .column
    width: 49.5%
    padding: 10px

.detail-sections-container .pusher
    height: 20vh

.detail-sections-container .avatar-section
    width: 100%
    margin-bottom: 20px
    background-color: $lightviolet
    color: white
    padding: 15px 20px
    font-size: 0.85rem

.detail-sections-container .avatar-section p 
    margin: 0.4rem 0 0.4rem 0
    opacity: 1

.detail-sections-container .avatar-section li 
    margin-left: 1rem
    opacity: 0.75
    list-style: disc

.detail-sections-container .avatar-section p span 
    opacity: 0.75

.detail-sections-container .avatar-section h4 
    color: $sandbrown
    font-size: 1.1rem
    font-weight: 300
    margin-bottom: 0.5rem

.detail-sections-container iframe
    border: none
    border-radius: 15px
    width: 100%
    height: 100%

.headshot-container
    width: 100%
    padding: 20px
    // height: 170px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    background-color: $gray3
    position: relative
    @media screen and (min-width: 769px)
        flex-direction: column
        padding: 25px
        // height: 220px


.headshot-container.light 
    background-color: white

.headshot-container .img-container
    width: 40%
    aspect-ratio: 1/1

.headshot-container .img-container img
    width: 100%
    border-radius: 50%

.headshot-inner-container
    height: 100%
    width: 45%
    display: flex
    flex-direction: column
    align-items: center
    @media screen and (min-width: 769px)
        width: 100%

.headshot-detail-container
    display: flex
    flex-direction: column
    margin-top: 10px
    width: 100%


.detail-sidebar-container h4 
    font-weight: 400
    font-size: 1rem
    margin-top: 0.6rem

.detail-sidebar-container p
    font-size: 0.9rem


.additional-segments-container
    width: 100%
    height: calc( 100% - 150px )
    background-color: $gray3
    padding: 10px 10px 20px 15px
    display: none
    flex-direction: column
    align-items: center
    margin-top: 20px
    @media screen and (min-width: 769px)
        display: flex



.additional-segments-container.light
    background-color: white


.additional-segments-container h4 
    margin-bottom: 15px
    text-align: center

.additional-segments-container .inner-container
    width: 100%
    display: flex
    flex-direction: column
    justify-content: start
    align-items: center
    overflow-y: scroll
    padding-right: 8px


.segment-preview-card
    display: flex
    flex-direction: column
    width: 100%
    border-radius: 10px
    padding: 10px 
    background-color: $lightwhite
    margin-bottom: 10px
    cursor: pointer

.light .segment-preview-card
    background-color: $darkwhite
    color: $gray3


.segment-preview-card h5 
    width: 100%
    font-size: 0.9rem
    font-weight: 400
    margin-bottom: 0.3rem

.segment-preview-card p 
    width: 100%
    font-size: 0.8rem
    font-weight: 200


.chat-btn-container .btn
    border-radius: 10px
    margin-top: 20px
    border: none
    cursor: pointer


.detail-optbar-container
    width: 100%
    height: 60px
    border: 0.5px solid $lightbrown
    border-radius: 35px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 10px

.optbar-btn
    height: 40px
    width: 40px
    border-radius: 50%
    border: 1px solid white
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.2rem
    color: white
    opacity: 0.7
    cursor: pointer

.optbar-btn:hover
    opacity: 0.95




