.view-container
    height: 100vh
    width: 100vw
    display: flex
    flex-direction: column 
    overflow: hidden
    // position: relative
    @media screen and (min-width: 769px)
        flex-direction: row

.view-main-container
    height: calc( 100vh - 60px )
    width: 100%
    background-color: $gray1
    padding: 5px
    position: relative
    @media screen and (min-width: 769px)
        padding: 12px 12px 12px 11px
        height: 100%


.view-main-container.light 
    background-color: white

.view-inner-container
    height: 100%
    width: 100%
    border-radius: 15px
    position: relative
    border-radius: 15px
    background-color: $gray2
    padding: 70px 10px
    @media screen and (min-width: 769px)
        padding: 60px 

.view-inner-container.light
    background-color: white

.view-inner-container.faded
    padding-bottom: 0

.section-title-container
    width: auto
    display: flex
    justify-content: flex-end
    color: $darkwhite
    z-index: 2
    position: absolute
    padding: 15px 20px 0 0  
    top: 18px
    right: 15px
    font-size: 1.2rem
    @media screen and (min-width: 769px)
        font-size: 1.5rem
        right: 30px

.section-title-container.light
    color: $gray3

.back-btn-container
    width: auto
    display: flex
    color: white
    font-size: 1.5rem
    z-index: 2
    position: absolute
    padding: 15px 20px 0 0  
    top: 18px
    left: 35px
    cursor: pointer

.back-btn-container.light
    color: $gray3

.view-body-container
    width: 100%
    height: 100%
    position: absolute
    z-index: 3
    padding: 50px 20px 10px 20px

.no-data-message
    height: 100%
    width: 100%
    padding: 0 20%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    color: $darkwhite

.no-data-message h3 
    font-size: 1.2rem
    margin-bottom: 30px
    color: $darkwhite

.no-data-message.light
    color: $gray3

.no-data-message.light h3 
    color: $gray3

.light .no-data-message h3
    color: $gray3

.no-data-message p
    font-size: 1rem
    margin-bottom: 15px
    opacity: 0.8

.view-cta
    margin-top: 25px
    font-size: 0.9rem
    background-color: $marketgreen
    color: black
    opacity: 0.9
    transition: scale 0.1s ease-in-out

.view-cta:hover
    opacity: 1
    scale: 1.05

.bottom-fader
    position: absolute
    width: calc(100% - 23px)
    height: 65px
    bottom: 5px
    border-bottom-left-radius: 15px
    border-bottom-right-radius: 15px
    background: linear-gradient(0deg, #333333 0%, #333333 30%, #3333330a 100%)
    @media screen and ( min-width: 769px)
        bottom: 12px

.bottom-fader.light
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%)

.main-container
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

.inner-container
    width: 100%
    min-height: 70%
    max-height: 90%
    overflow: scroll
    background-color: $gray3
    display: flex
    flex-direction: column
    align-items: center
    padding: 20px
    @media screen and (min-width: 769px)
        width: 700px
        padding: 30px
        min-height: 60%

.light .inner-container
    background-color: white

.view-inner-container.faded .no-data-message
    margin-top: -30px