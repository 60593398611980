.new-input-container
    border: 1px solid $marketgreen
    width: 100%
    display: flex
    justify-content: center
    color: white

.light .new-input-container
    color: $gray3

.new-input-container button
    background: none
    border: none
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    padding: 10px
    color: white
    cursor: pointer
    font-size: 1rem
    opacity: 0.8
    @media screen and (min-width: 769px)
        padding: 15px

.light .new-input-container button
    color: $marketgreen

.new-input-container button:hover
    opacity: 1

.new-input-container h4 
    font-weight: 300
    margin-right: 1rem

.new-input-container .input-container
    position: relative
    width: 100%
    padding: 20px

.new-input-container .close-input-btn
    cursor: pointer
    position: absolute
    top: 5px
    right: 12px
    padding: 5px
    font-size: 1rem

.new-input-container .input-container form
    width: 80%

.new-input-container .input-container .input-row
    display: flex

.new-input-container .input-container input
    background: none
    border: none
    border-bottom: 1px solid $lightbrown
    width: 100%
    margin-right: 1rem
    color: white
    padding-bottom: 5px
    font-size: 1rem

.light .new-input-container .input-container input
    border-bottom: 0.5px solid $gray4
    color: $gray3

.new-input-container .input-container input::placeholder
    color: $gray4
    font-style: italic

.light .new-input-container .input-container input::placeholder
    color: $gray3


.new-input-container .add-value-btn
    font-size: 1.2rem
    cursor: pointer

.light .new-input-container .add-value-btn
    color: $marketgreen

.subitem-row input::placeholder
    color: $darkwhite
    font-style: italic

.light .subitem-row input::placeholder
    color: $gray3

.companies .toggle-btn-container
    width: 100%
    display: flex
    align-items: center
    justify-content: end

.companies .toggle-btn-container p 
    color: $darkwhite
    opacity: 1

.companies.light .toggle-btn-container p 
    color: $gray2

.companies .toggle-btn-container p.inactive
    opacity: 0.5

.companies .toggle-btn-container .bold
    font-weight: 500
    margin-right: 1rem


.companies .toggle-btn
    justify-content: flex-start
    background: $gray2
    opacity: 0.9
    cursor: pointer
    height: 1.2rem
    width: 2.6rem
    border: none
    padding: 0.1rem
    display: flex
    align-items: center
    border-radius: 0.6rem
    margin: 0 0.5rem

.companies .toggle-btn span 
    background-color: $gray5
    height: 0.9rem
    width: 0.9rem
    display: inline-block
    border-radius: 50%

.companies .toggle-btn.default 
    justify-content: flex-end

.companies.light .toggle-btn
    background: $gray4

.companies.light .toggle-btn span 
    background-color:  white


.companies .toggle-btn:hover
    opacity: 1




