.credits .inner-container
    color: $darkwhite
    position: relative
    min-height: 80%
    padding-bottom: 20px
    width: 1000px
    padding-top: 2.5rem

.credits .inner-container h4 
    font-size: 1.2rem

.credits ul 
    list-style: disc 
    padding: 0 10px

.light .credits ul 
    color: $gray3

.credits span 
    font-weight: 600

.credits li 
    margin: 0.8rem 0

.credit-section
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    
.credit-section h4 
    margin-bottom: 1rem

.credits .purchase-button
    background-color: $marketgreen
    border: none
    padding: 10px 20px
    margin: 1rem 0
    cursor: pointer
    border-radius: 5px
    font-weight: 600
    opacity: 0.9

.credits .purchase-button:hover
    opacity: 1

.light.credits .purchase-button
    background-color: $marketgreen

.credits .credit-row
    display: flex
    width: 100%
    text-align: center
    align-items: center
    padding: 10px 0
    border-bottom: 0.5px solid $gray4
    font-size: 0.9rem
    font-weight: 300

.credits .credit-row.last
    border-bottom: none
    

.credits .credit-row.header
    font-weight: 500

.credit-row .size-l
    width: 50%

.credit-row .size-m
    width: 40%

.credit-row .size-s
    width: 20%

.credits-transaction-container
    border: 1px solid $gray4
    padding: 10px

.credits .credit-transaction-list
    width: 100%

.credits .credit-balance-container
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-evenly
    padding: 20px
    font-weight: 500

.credits .close-product-section
    position: absolute
    top: 20px
    right: 20px
    font-size: 1.2rem
    cursor: pointer

.credits .product-container
    width: 100%
    height: 100%
    margin-top: 1.5rem
    // border:1px solid red
    display: flex
    flex-direction: column
    align-items: flex-start
    @media screen and (min-width: 769px)
        flex-wrap: wrap
        flex-direction: row
        justify-content: space-evenly

.product-container .product-card
    padding: 20px
    padding-bottom: 50px
    border: 1px solid $gray4 
    width: 100%
    margin: 1rem 0
    // height: 500px
    display: flex
    flex-direction: column
    font-size: 0.9rem
    position: relative
    @media screen and (min-width: 769px)
        width: 30%
        height: 520px

.product-container .product-card.current
    border: 2px solid $marketgreen

.product-container .product-card h5 
    color: $darkwhite
    font-size: 1rem
    margin-bottom: 1rem
    text-align: center

.light .product-container .product-card h5 
    color: $gray2

.product-container .current-sign
    width: calc( 100% + 40px )
    margin-top: calc( -1.5rem + (-20px) )
    margin-left: -20px
    // margin-top: -1.5rem
    height: 1.5rem
    background-color: $marketgreen 
    border-top-left-radius: 7px
    border-top-right-radius: 7px


.product-container .price-div
    width: 100%
    display: flex
    justify-content: center
    margin-bottom: 1rem
    align-items: flex-end


.product-container .price-div .big 
    font-size: 2.3rem

.product-container .price-div .detail 
    margin: 0 0 0.4rem 0.5rem
    opacity: 0.8

.credits .product-purchase-btn
    padding: 10px 10px
    margin: 1.5rem 0
    background: $marketgreen
    opacity: 0.9
    cursor: pointer
    border-radius: 5px
    border: none

.credits .product-purchase-btn.learn
    background: none
    border: 1px solid $darkwhite
    color:  $darkwhite
    margin-top: 1rem
    @media screen and (min-width: 769px)
        margin-top: auto

.credits .current-plan-sign
    padding: 10px 10px
    margin: 1.5rem 0
    background: $marketgreen
    opacity: 0.9
    cursor: pointer
    border-radius: 5px
    border: none
    text-align: center
    color: black
    opacity: 0.5

.credits .product-purchase-btn:hover
    opacity: 1

.view-container.success
    background-color: $gray2

.light.view-container.success
    background-color: white

.success-payment-icon-container
    font-size: 4rem
    color: $marketgreen
    margin-bottom: 3rem
    animation: bounceSuccess 0.9s ease-in-out

.credits .current-marker-div
    // height: 2rem
    // border: 1px solid red
    padding: 0.3rem 0
    width: 120px
    display: flex
    align-items: center
    justify-content: center
    background-color: $darkwhite
    color: $gray3
    // position: absolute
    // bottom: 18px
    margin-left: -22px
    margin-top: 1rem 
    border-radius: 2px
    border-bottom-right-radius: 18px
    font-weight: 500

.light .credits .current-marker-div
    background-color: $gray6

.light .credits .current-marker-div p 
    // color: white


@keyframes bounceSuccess 
  0% 
    transform: scale(1)
    opacity: 0.8
  
  30% 
    transform: scale(1.2) 
    opacity: 1
  
  50% 
    transform: scale(0.9)
    opacity: 0.9
  
  70% 
    transform: scale(1.05)
    opacity: 1
  
  100% 
    transform: scale(1)
